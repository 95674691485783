import React from "react"
import CheckList from "./checkList/CheckList"
import Section from "../../common/Section"
import GalleryContent from "./GalleryContent"
import Button from "../../common/Button"
import { navigate } from "gatsby"
import AboutUs from "../AboutUs"

const Gallery = ({ type, images }) => {
  const items = ["Builders", "Contractors", "Interior designers"]

  return (
    <>
      <Section>
        <CheckList title="We work with" items={items} />
      </Section>
      <Section>
        <GalleryContent type={type} images={images} />
        <Button
          label="Learn about our process"
          onClick={() => navigate("/process")}
        />
      </Section>
    </>
  )
}

export default Gallery
