import React from "react"
import Layout from "../../components/common/Layout"
import ActionBar from "../../components/actionBar/ActionBar"
import Gallery from "../../components/sections/gallery/Gallery"
import { graphql, useStaticQuery } from "gatsby"

const Transitional = () => {
  const imagesData = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "transitional" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <ActionBar />
      <Gallery type="Transitional" images={imagesData.allFile.edges} />
    </Layout>
  )
}

export function Head() {
  return <title>Transitional Kitchens</title>
}

export default Transitional
