import React from "react"
import styled from "styled-components"
import ImageBox from "../../common/ImageBox"

const GalleryContent = ({ type, images }) => {
  return (
    <Wrapper>
      <div className="info">
        <p>{type} kitchens</p>
        <p>1/{images.length}</p>
      </div>

      <div className="images">
        {images.map((imageData, i) => (
          <ImageBox
            key={i}
            data={imageData.node.childImageSharp}
            alt={`${type} kitchen`}
            eager={i === 0 ? true : false}
          />
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 3rem;
  .info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .images {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
`
export default GalleryContent
