import React from "react"
import styled from "styled-components"

const Button = ({ label, onClick, color }) => {
  return <Wrapper onClick={() => onClick()}>{label}</Wrapper>
}

const Wrapper = styled.button`
  position: relative;
  outline: none;
  border: none;

  font-size: var(--p-size);
  font-weight: 400;

  background-color: rgba(255, 255, 255, 0.05);
  color: var(--secondary-color);

  padding-block: 1.5rem;
  padding-inline: 1.5rem;

  width: 100%;
  border-radius: var(--border-radius);
  transition: 100ms;

  @media (hover: hover) {
    :hover {
      background-color: var(--secondary-color);
      color: var(--primary-color);
      font-weight: 500;
    }
  }
  cursor: pointer;
`

export default Button
