import React from "react"
import styled from "styled-components"
import { formatImageData } from "../../scripts/ImageQuery"
import { graphql, useStaticQuery } from "gatsby"
import ImageBox from "../common/ImageBox"

const AboutUs = () => {
  const image = formatImageData(
    useStaticQuery(graphql`
      query {
        file(relativePath: { eq: "family.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `).file.childImageSharp,
    "Modern White Kitchen"
  )

  return (
    <Wrapper>
      <ImageBox data={image.data} alt={image.alt} eager={true} />

      <h1>Who are we?</h1>
      <p>
        We are a family of highly skilled and dedicated designers and craftsmen
        who value quality, design and efficiency. Through generational trial and
        error, we have created a motivating team that continues to push forward
        thinking and community to the forefront of every job, project and client
        relationship.
      </p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  h1 {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }
`

export default AboutUs
