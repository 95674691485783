import React from "react"
import styled from "styled-components"
import Check from "../../../../assets/icons/Check.svg"

const CheckListItem = ({ text }) => {
  return (
    <Wrapper>
      <Check />
      <h2>{text}</h2>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-left: 6px;
`

export default CheckListItem
