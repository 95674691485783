import React from "react"
import styled from "styled-components"
import CheckListItem from "./CheckListItem"

const CheckList = ({ title, items }) => {
  return (
    <Wrapper>
      <h1>{title}</h1>
      <div className="items">
        {items.map((label, i) => (
          <CheckListItem key={i} text={label} />
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 3rem;
  h1 {
    margin-bottom: 1rem;
  }
  .items {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`
export default CheckList
